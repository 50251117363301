
import { GenericResponse } from './genericResponse';
import { User } from './userPojos';

/** POJOs per il primo passo della fase di registrazione */

class RegistrationFstStep {
  public component: string;
  public email: string;
}

class FstStepResponse extends GenericResponse {
  public data: [{
    user_exist: boolean
  }];
}

/** POJOs per il secondo passo della fase di registrazione */

class RegistrationSndStep {
  public component: string;
  public email: string;
  public flag_marketing     = 0;
  public flag_privacy       = 0;
  public flag_profilazione  = 0;
  public flag_cessioneterzi = 0;
  public role_code = 'codeploy-norole';
  public type = 1;
}

/** POJOs registrazione da invito */

class FriendRegistration {
  email: string ;
  flag_cessioneterzi = 0 ;
  flag_marketing     = 0 ;
  flag_privacy       = 0 ;
  flag_manleva       = 0 ;
  user_firstname: string ;
  user_lastname : string ;
  user_phone    : string ;
  role_code = "codeploy-norole" ;
  type = 1 ;
}

class FriendRegistrationResponse extends GenericResponse {
  public data: User[];
}

export { RegistrationFstStep,
         FstStepResponse,
         RegistrationSndStep,
         FriendRegistration,
         FriendRegistrationResponse };
