import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PrivateSlot } from '../../models/availabilityPojos';

@Injectable({
  providedIn: 'root'
})
export class CalendarObservableService {

  ///////////////////////////////////////// OBSERVABLE STREAM LIST

  public refreshCalendarSource    = new Subject<void>();
  public reservationActionsSource = new Subject<PrivateSlot>();
  public reservationDetailsSource = new Subject<PrivateSlot>();
  public publicInfoSource         = new Subject<void>();

  public refreshCalendar$    = this.refreshCalendarSource.asObservable();
  public reservationActions$ = this.reservationActionsSource.asObservable();
  public reservationDetails$ = this.reservationDetailsSource.asObservable();
  public publicInfo$         = this.publicInfoSource.asObservable();
}
