import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GenericResponse } from '../../models/genericResponse';
import { HttpClient } from '@angular/common/http';
import { Invitation, InvitationValidationResponse } from '../../models/invitationPojos';
import { User } from '../../models/userPojos';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {

  private uri: string = environment.endpoint + environment.basePath;

  constructor(private http: HttpClient) { }

  public sendInvitations(user: User, inv: Invitation)
  : Promise<GenericResponse> {

    const url: string = this.uri + 'private/booking/v2/invitationslot';

    return this.http.post<GenericResponse>(url, inv, {
        headers: environment.header,
        params : {
          component  : user.user_component,
          user_token : user.user_token }
    }).toPromise();
  }

  public validateInvitation(user: User)
  : Promise<InvitationValidationResponse> {

    const url: string = this.uri + 'public/booking/v2/invitation/validate';

    return this.http.post<InvitationValidationResponse>(url, user.auth, {
        headers: environment.header,
        params : { component : user.user_component }
    }).toPromise();
  }

  public confirmInvitation(user: User)
    : Promise<GenericResponse> {

    const url: string = this.uri + 'public/booking/v2/invitation/confirm';

    return this.http.post<GenericResponse>(url, {
      otp: user.auth.otp, user_uuid: user.user_uuid,
    }, { headers: environment.header,
         params : { component  : user.user_component,
                    user_token : user.user_token }
  }).toPromise();
  }
}
