import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { User } from '../models/userPojos';
import { LoginService } from '../services/login/login.service';
import { Router } from '@angular/router';
import { AppLoadService } from '../services/configuration/app-load.service';
import { Configuration } from '../models/configurationPojos';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.css']
})
export class MainHeaderComponent implements OnInit, AfterViewInit {

  @Input() user: User = null;

  public configuration = this.configService.getConfiguration();

  constructor(private loginService: LoginService,
    private router: Router,
    private configService: AppLoadService) { }

  ngOnInit() {
    // $(window).on('scroll', () => {          // sticky main header
    //   if ($(window).scrollTop() > 60) {
    //     $('.header_area').addClass('sticky slideInDown');
    //   } else {
    //     $('.header_area').removeClass('sticky slideInDown'); }
    // });
  }
  ngAfterViewInit() {
    jQuery('#nav').onePageNav({             // init scroll on nav link click
      currentClass: 'active',
      scrollSpeed: 2000,
      easing: 'easeOutQuad'
    });
  }
  public logout() {
    this.loginService.logout(); this.router.navigate(['/']);
  }
}
