
import { Component, OnInit, ChangeDetectionStrategy,
         ViewEncapsulation, ChangeDetectorRef, Input, OnDestroy } from '@angular/core';
import { CalendarEvent, CalendarView, DAYS_OF_WEEK,
         CalendarWeekViewBeforeRenderEvent } from 'angular-calendar';
import { ViewPeriod, DayViewHour, EventAction, WeekViewHourColumn } from 'calendar-utils';
import { CalendarFilter } from '../models/filterPojos';
import { CalendarService } from '../services/calendar/calendar.service';
import { Observable, from, Subscription } from 'rxjs';
import { startOfWeek, endOfWeek, setHours } from 'date-fns';
import { LoginService } from '../services/login/login.service';
import { User } from '../models/userPojos';
import { Slot, PrivateSlot, PublicAvailabilityResponse,
         PrivateAvailabilityResponse } from '../models/availabilityPojos';
import { CalendarObservableService } from '../services/calendar/calendar.observable.service';
import { DeviceDetectorService, DeviceType } from '../utils/devices/device-detector.service';
import { AppLoadService } from '../services/configuration/app-load.service';

@Component({
  selector: 'app-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css', './calendar.colors.css'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements OnInit, OnDestroy {

  @Input() public = true ;

  public configuration = this.configService.getConfiguration();

  public locale = 'it';
  public CalendarView = CalendarView;
  public view: CalendarView = CalendarView.Week;
  public viewDate: Date = new Date();
  public weekendDays: number[] = [
      DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY
  ];
  public weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  private viewPeriod: ViewPeriod;
  public events$: Observable<CalendarEvent<any>[]> = null;
  public dayStartHour = 10;
  public dayEndHour = 21;
  public daysInWeek = 0;
  public device: DeviceType = null;
  public deviceType = DeviceType;

  public  sportFilter = {};
  public  filter: CalendarFilter = new CalendarFilter();
  private refreshSubscription: Subscription;
  private deviceSubscription : Subscription;

  constructor(
    private calendarService: CalendarService,
    private cdr: ChangeDetectorRef,
    private loginService: LoginService,
    private eventService: CalendarObservableService,
    private deviceService: DeviceDetectorService,
    private configService: AppLoadService) { }

  /////////////////////////////////////////////////////// INITIALIZE

  ngOnInit() {

    this.deviceSubscription = this.deviceService
      .deviceChange.subscribe(() => {
        this.daysInWeek = this.getDaysInWeekBasedOnDeviceType();
        this.cdr.markForCheck();
    });
    this.daysInWeek = this.getDaysInWeekBasedOnDeviceType();

    const opts = { weekStartsOn: this.weekStartsOn };
    const startPeriod = startOfWeek(this.viewDate, opts);
    const endPeriod   = endOfWeek  (this.viewDate, opts);

    this.viewPeriod = { events: [],
      start: setHours(startPeriod, this.dayStartHour),
      end  : setHours(endPeriod, this.dayEndHour + 1)
    };
    this.filter.component = this.configuration.component;
    this.refreshSubscription =  this.eventService
        .refreshCalendar$.subscribe(() => this.refreshView());
    this.getSlotsAvailability();
  }

  ngOnDestroy() { this.refreshSubscription.unsubscribe();
                  this.deviceSubscription.unsubscribe(); }

  /////////////////////////////////////////////////////// VIEWS UTILS BASED ON DEVICE

  setView(view: CalendarView) {
    this.daysInWeek = view === CalendarView.Week ?
      this.getDaysInWeekBasedOnDeviceType() : 1;
  }

  private getDaysInWeekBasedOnDeviceType(): number {
    this.device = this.deviceService.deviceType;
    switch (this.device) {
      case DeviceType.MOBILE : return 1;
      case DeviceType.TABLET : return 3;
      case DeviceType.DESKTOP: return 0;
    }
  }

  /////////////////////////////////////////////////////// ON VIEW CHANGE

  private refreshView(): void { this.getSlotsAvailability(); }

  public changeWeekView(evt: CalendarWeekViewBeforeRenderEvent) {

    this.markNoEventSlots(evt.hourColumns);
    const oldPeriod  = this.viewPeriod;
    evt.period.start = setHours(evt.period.start, this.dayStartHour);
    evt.period.end   = setHours(evt.period.end, this.dayEndHour + 1);
    this.viewPeriod  = evt.period;
    if (evt.period.end.getTime()   !== oldPeriod.end.getTime() ||
        evt.period.start.getTime() !== oldPeriod.start.getTime()) {
      this.getSlotsAvailability();
    }
  }
  private markNoEventSlots(hourColumns: WeekViewHourColumn[]): void {

    hourColumns.forEach(col => {
      const events: CalendarEvent<any>[] = col.events.map(e => e.event);
      const hours: DayViewHour[] = col.hours;
      const eventsTime: number[] = events.map(e => e.start.getTime());

      hours.filter(hour => {
        const hTime: number = hour.segments[0].date.getTime();
        return !eventsTime.some(evtTime => evtTime === hTime);
      }).map(hour => hour.segments[0])
      .forEach(segment => segment.cssClass = 'hour-slot-lock');
    });
  }

  ////////////////////////////////////////// ON ACTIVITY SELECTION

  onSportSelection() {
    this.filter.sport = Object
      .keys(this.sportFilter)
      .filter(sport => this.sportFilter[sport] !== false);

    this.getSlotsAvailability();
  }

  /////////////////////////////////////////////////////// GET AVAILABILITY

  private getSlotsAvailability() {

    this.filter = Object.assign (this.filter, {
      from: this.viewPeriod.start.getTime(),
      to  : this.viewPeriod.end.getTime()
    });
    this.events$ = this.public ?
      from(this.loadPublicAvailability()) :
      from(this.loadPrivateAvailability());

    Promise.resolve(null).then(() => this.cdr.detectChanges());
  }

  private loadPublicAvailability(): Promise<CalendarEvent<any>[]> {

    return this.calendarService.getPublicAvailability(this.filter)
      .then(res => this.onAvailabilitySuccess(res),
            err => this.onAvailabilityError(err));
  }
  private loadPrivateAvailability(): Promise<CalendarEvent<any>[]> {

    const user: User = this.loginService.getUser();
    return this.calendarService
      .getPrivateAvailability(user, this.filter, false, false)
      .then(res => this.onAvailabilitySuccess(res),
            err => this.onAvailabilityError(err));
  }

  private onAvailabilitySuccess(
      res: PrivateAvailabilityResponse |
           PublicAvailabilityResponse): Promise<CalendarEvent<any>[]> {
    if (res.status_code === 200) {
      this.cdr.markForCheck();
      return this.generateSlots(res.data);
    } else {
      return this.onAvailabilityError(res.msg); }
  }
  private onAvailabilityError(error: string) {
    console.error(error);
    return Promise.reject(error);
  }

  /////////////////////////////////////////////////////// SLOT GENERATION

  private generateSlots(slots: (Slot | PrivateSlot)[]): Promise<CalendarEvent<any>[]> {

    const events: CalendarEvent<any>[] = slots.map(slot => {
      const libero = slot.slot_stato === 'LIBERO';
      const owner  = slot.hasOwnProperty('slot_reservation');

      return { title: '', meta: { slot },
          start: new Date(slot.slot_timestamp_from),
          end  : new Date(slot.slot_timestamp_to),
          actions: this.public ?
              this.publicSlotCallToAction() :
              this.privateSlotCallToAction(slot as PrivateSlot),
          cssClass: 'slot ' + slot.slot_category
                  + (libero ? ' empty-slot'  : '')
                  + (owner  ? ' reservation' : '')
        };
    });
    return Promise.resolve<CalendarEvent<any>[]>(events);
  }

  private publicSlotCallToAction(): EventAction[] {

    let action: ({ event }: { event: CalendarEvent<any>; }) => any ;
    action = e => this.eventService.publicInfoSource.next();
    return [{ cssClass: 'slot-event-cta',
              label: '', onClick: action }];
  }

  private privateSlotCallToAction(slot: PrivateSlot): EventAction[] {

    let action: ({ event }: { event: CalendarEvent<any>; }) => any = e => void(0);

    if (slot.slot_stato === 'OCCUPATO' && slot.slot_reservation) {
      action = e => this.eventService
        .reservationDetailsSource.next(slot);
    }
    else if (slot.slot_stato === 'LIBERO') {
      action = e => this.eventService
        .reservationActionsSource.next(slot);
    }
    return [{ cssClass: 'slot-event-cta', label: '', onClick: action }];
  }
}
