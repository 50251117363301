import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserAuth, UserAuthResponse, User, UserInfoResponse } from '../../models/userPojos';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private uri: string = environment.endpoint + environment.basePath;

  constructor(private http: HttpClient) { }

  confirmOTP(auth: UserAuth): Promise<UserAuthResponse> {

    const url: string = this.uri + 'public/core/v2/confirmotp';

    return this.http.get<UserAuthResponse>(url, {
        headers: environment.header,
        params : { token : auth.token,
                   otp   : auth.otp }
    }).toPromise();
  }

  findUserInfo(user: User): Promise<UserInfoResponse> {

    const url: string = this.uri + 'private/core/v2/users/user/find';

    return this.http.get<UserInfoResponse>(url, {
        headers: environment.header,
        params : { component  : user.user_component,
                   user_token : user.user_token }
    }).toPromise();
  }

  findUserByUuid(user: User, uuid: string) {

    const url: string = this.uri + 'private/core/v2/users/user/findbyuuid';

    return this.http.get<UserInfoResponse>(url, {
        headers: environment.header,
        params : { component  : user.user_component,
                   user_token : user.user_token,
                   user_uuid  : uuid,
                   checkcomponent : "false"
      }
    }).toPromise();
  }

  public saveUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
  }
  public getUser(): User {
    return JSON.parse(localStorage.getItem('user'));
  }
  public logout() { localStorage.removeItem('user'); }
}
