import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { NgxFooterRevealModule } from 'ngx-footer-reveal/ngx-footer-reveal';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarComponent } from './calendar/calendar.component';
import { PrivateCalendarPageComponent } from './private-calendar-page/private-calendar-page.component';
import { MainFooterComponent } from './main-footer/main-footer.component';
import { RegistrationComponent } from './landing-page/registration/registration.component';
import { ReservationComponent } from './private-calendar-page/reservation/reservation.component';
import { MainHeaderComponent } from './main-header/main-header.component';
import { MyReservationsPageComponent } from './my-reservations-page/my-reservations-page.component';
import { HttpErrorInterceptor } from './utils/http-error-interceptor';
import { AcceptInvitePageComponent } from './accept-invite-page/accept-invite-page.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ErrorPageComponent } from './error-page/error-page.component';
import { DeleteReservationPopupComponent } from './my-reservations-page/delete-reservation-popup/delete-reservation-popup.component';
import { SpinnerComponent } from './utils/spinner/spinner.component';
import { AppLoadModule } from './app-load.module';

registerLocaleData(localeIt, 'it');

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    CalendarComponent,
    PrivateCalendarPageComponent,
    MainFooterComponent,
    RegistrationComponent,
    ReservationComponent,
    MainHeaderComponent,
    MyReservationsPageComponent,
    AcceptInvitePageComponent,
    ErrorPageComponent,
    DeleteReservationPopupComponent,
    SpinnerComponent
  ],
  imports: [
    AppLoadModule,
    BrowserModule,
    AppRoutingModule,
    NgxFooterRevealModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory }),
    FormsModule,
    HttpClientModule,
    NgbModule, NgxPaginationModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ DeleteReservationPopupComponent ]
})
export class AppModule { }
