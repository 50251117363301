import { Injectable } from '@angular/core';
import { RegistrationFstStep, FstStepResponse,
         RegistrationSndStep, FriendRegistration, FriendRegistrationResponse } from '../../models/registrationPojos';
import { HttpClient } from '@angular/common/http';
import { GenericResponse } from '../../models/genericResponse';
import { environment } from '../../../environments/environment';
import { User } from '../../models/userPojos';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  private uri: string = environment.endpoint + environment.basePath;

  constructor(private http: HttpClient) { }

  userExists(userData: RegistrationFstStep): Promise<FstStepResponse> {

    const url: string = this.uri + 'public/core/v2/exists';

    const body = { user_email: userData.email };
    return this.http.post<FstStepResponse>(url, body, {
        headers: environment.header,
        params : {
          component: userData.component}
    }).toPromise();
  }

  registerUserOTP(privacyData: RegistrationSndStep): Promise<GenericResponse> {

    const url: string = this.uri + 'public/core/v2/registerotp';

    return this.http.post<FstStepResponse>(url, privacyData, {
        headers: environment.header,
        params : { component: privacyData.component }
    }).toPromise();
  }

  loginOTP(userData: RegistrationFstStep): Promise<GenericResponse> {

    const url: string = this.uri + 'public/core/v2/loginotp';

    const body = { user_email: userData.email };
    return this.http.post<FstStepResponse>(url, body, {
        headers: environment.header,
        params : { component: userData.component }
    }).toPromise();
  }

  saveUser(user: User, registration: FriendRegistration)
  : Promise<FriendRegistrationResponse> {

    const url: string = this.uri + 'public/core/v2/users/save';

    return this.http.post<FriendRegistrationResponse>(url, registration, {
        headers: environment.header,
        params : { component: user.user_component }
    }).toPromise();
  }
}
