import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateUtilsService {

  /* Funzione di parsificazione delle date per risolvere
   * un problema di compatibilità con Safari */
  public parse(date: string): Date {
    const parsed = Date.parse(date);
    if (!isNaN(parsed)) {
      return new Date(parsed); }
    const safariFix = date.replace(/-/g, '/').replace(/[a-z]+/gi, ' ');
    return new Date(Date.parse(safariFix));
  }
}
