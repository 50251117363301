import { Component, OnInit } from '@angular/core';
import { AppLoadService } from '../services/configuration/app-load.service';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.css']
})
export class MainFooterComponent implements OnInit {

  public configuration = this.configService.getConfiguration();

  constructor(private configService: AppLoadService) { }

  ngOnInit() { }
}
