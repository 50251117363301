import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Reservation, ReservationResponse } from '../../models/reservationPojos';
import { HttpClient } from '@angular/common/http';
import { User } from '../../models/userPojos';
import { GenericResponse } from 'src/app/models/genericResponse';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  private uri: string = environment.endpoint + environment.basePath;

  constructor(private http: HttpClient) { }

  public slotReservation(user: User, reservation: Reservation): Promise<ReservationResponse> {

    const url: string = this.uri + 'private/booking/v2/slotreservation';

    return this.http.post<ReservationResponse>(url, reservation, {
        headers: environment.header,
        params : {
          component  : user.user_component,
          user_token : user.user_token }
    }).toPromise();
  }

  public reservationDelete(user: User, id: number): Promise<GenericResponse> {

    const url: string = this.uri + 'private/booking/v2/delete';

    return this.http.delete<GenericResponse>(url, {
        headers: environment.header,
        params : {
          component  : user.user_component,
          user_token : user.user_token,
          slot_id    : id.toString() }
    }).toPromise();
  }
}
