import { GenericResponse } from './genericResponse';

class Reservation {
    slot_id: number ;
    user_firstname: string ;
    user_fiscalcode: string ;
    user_lastname: string ;
    user_phone: string ;
    flag_manleva: number ;
}

class ReservationResponse extends GenericResponse {
  data: [{
    rese_id: number ;
    rese_date_reservation: Date ;
    rese_date_update: Date ;
  }];
}
export { Reservation, ReservationResponse };
