
import { HttpHeaders } from '@angular/common/http';
export const environment = {
  production: false,
  rootDomain: '.frispobooking.com',
  configEndpoint: 'https://api-web-dev.codeploy.cloud',    // TODO: da definire
  configBasePath: '/api/web/config',
  endpoint: 'https://api-web-dev.codeploy.cloud',
  basePath: '/api/web/',
  header: new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('x-api-key', 'AFj1lrwNepYs0BUCGa1D5Du5kgMvV8W7y2YWWET9')
    .set('Authorization', 'Basic ' + btoa('saasbooking:saasbooking')),
};
// import { HttpHeaders } from '@angular/common/http';

// export const environment = {
//   production: true,

//   rootDomain: '.frispobooking.com',
//   configEndpoint: 'https://api-web.codeploy.cloud/api/web/config',    // TODO: da definire
//   configBasePath: '',

//   endpoint: 'https://api-web.codeploy.cloud',
//   basePath: '/api/web/',
//   header: new HttpHeaders()
//     .set('Content-Type', 'application/json')
//     .set('x-api-key', 'vfXGPXAJpbaaTKjAYmoxN9WQPQAm0LOv5vJUZ4lo')
//     .set('Authorization', 'Basic '+btoa('saasbooking2020!:saasb00k1ng2020!')),


// };
