import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { UserAuth, User } from '../models/userPojos';
import { LoginService } from '../services/login/login.service';
import { AppLoadService } from '../services/configuration/app-load.service';

@Component({
  selector: 'app-private-calendar-page',
  templateUrl: './private-calendar-page.component.html',
  styleUrls: ['./private-calendar-page.component.css']
})
export class PrivateCalendarPageComponent implements OnInit {

  public user$: Promise<User> = null;
  public configuration = this.configService.getConfiguration();

  constructor(private route: ActivatedRoute,
              private loginService: LoginService,
              private location: Location,
              private router: Router,
              private configService: AppLoadService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.location.go('/myCalendar');
      this.user$ = this.getCurrentUser({
        token: params.token, otp: params.otp
      }).then(usr => {
        if (params.page === 'reserv') {
          this.router.navigate(['/myReservations']); }
        return usr; });
    }, error => this.onError(error));
  }

  private getCurrentUser(auth: UserAuth): Promise<User> {

    const user: User = this.loginService.getUser();
    if (user === null ||
        auth.token && user.auth.token !== auth.token) {
      this.loginService.logout();
      return this.checkAuthentication(auth);
    }
    else { return Promise.resolve(user); }
  }

  private checkAuthentication(authInfo: UserAuth): Promise<User> {

    return this.loginService.confirmOTP(authInfo)
        .then(res => {
          if (res.status_code === 200) {
            let user: User = new User();
            user = Object.assign(user, { auth: authInfo }, res.data[0]);
            return this.loadUserInfo(user);
          } else {
            this.onError(res.msg); return null; }
        },
        error => { console.log(error); return null; });
  }

  private loadUserInfo(user: User): Promise<User> {

    return this.loginService.findUserInfo(user)
      .then(res => {
        if (res.status_code === 200) {
          Object.keys(res.data[0])
            .filter (k => k in user.info)
            .forEach(k => user.info[k] = res.data[0][k]);
          this.loginService.saveUser(user);
          return user;
        } else {
          this.onError(res.msg); return null; }
      },
      error => { console.log(error); return null; });
  }

  private onError(error: any) {
    this.router.navigate(['/error'], { skipLocationChange: true});
    console.error(error);
  }
}
