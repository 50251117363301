import { Injectable } from '@angular/core';
import { CalendarFilter } from '../../models/filterPojos';
import { HttpClient } from '@angular/common/http';
import { User } from '../../models/userPojos';
import { PublicAvailabilityResponse,
         PrivateAvailabilityResponse,
         Slot, PrivateSlot} from '../../models/availabilityPojos';
import { environment } from '../../../environments/environment';
import { DateUtilsService } from '../../utils/date/date-utils.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  private uri: string = environment.endpoint + environment.basePath;

  constructor(private http: HttpClient,
              private dateUtils: DateUtilsService) { }

  getPublicAvailability(filter: CalendarFilter)
  : Promise<PublicAvailabilityResponse> {

    const place = filter.component;
    const url: string = this.uri + 'public/booking/v2/availabilityarray';

    return this.http.post<PublicAvailabilityResponse>(url, filter, {
        headers: environment.header,
        params : { component: place }
    })
    .toPromise().then(res => {
      res.data.forEach(slot => this.parseDate(slot));
      return res;
    });
  }

  getPrivateAvailability(
    user: User, filter: CalendarFilter, mine: boolean, owner: boolean)
  : Promise<PrivateAvailabilityResponse> {

    const url: string = this.uri + 'private/booking/v2/availabilityarray';

    return this.http.post<PrivateAvailabilityResponse>(url, filter, {
        headers: environment.header,
        params : { component  : user.user_component,
                   user_token : user.user_token,
                   userOnly   : mine.toString(),
                   userOwner  : owner.toString() }
    })
    .toPromise().then(res => {
      res.data.forEach(slot => this.parseDate(slot));
      return res;
    });
  }

  private parseDate(slot: Slot | PrivateSlot): void {

    const from: Date = this.dateUtils.parse(slot.slot_timestamp_from);
    const to  : Date = this.dateUtils.parse(slot.slot_timestamp_to);
    slot.slot_timestamp_from = from.toString();
    slot.slot_timestamp_to   = to.toString();
  }
}
