import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { User, UserInfo } from '../models/userPojos';
import { InvitationService } from '../services/invitation/invitation.service';
import { FriendInvitation } from '../models/invitationPojos';
import { LoginService } from '../services/login/login.service';
import { FriendRegistration } from '../models/registrationPojos';
import { NgForm } from '@angular/forms';
import { RegistrationService } from '../services/registration/registration.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppLoadService } from '../services/configuration/app-load.service';

@Component({
  selector: 'app-accept-invite-page',
  templateUrl: './accept-invite-page.component.html',
  styleUrls: ['./accept-invite-page.component.css']
})
export class AcceptInvitePageComponent implements OnInit {

  @ViewChild('popup_error', { static: true }) private popupError: TemplateRef<any>;

  public configuration = this.configService.getConfiguration();

  public submitting = false;
  public submitted  = false;
  public invitation$: Promise<FriendInvitation> = null;
  public registration: FriendRegistration = new FriendRegistration();
  public user: User = null;

  constructor(private route: ActivatedRoute,
              private invService: InvitationService,
              private router: Router,
              private loginService: LoginService,
              private regService: RegistrationService,
              private modalService: NgbModal,
              private location: Location,
              private configService: AppLoadService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
        this.location.go('/acceptInvite');
        this.user = {
          flg_privacy: 1,
          auth: { token: params.token,
                  otp: params.otp },
          user_component: params.component
        } as User;
        this.invitation$ = this.validateInvitation();
      },
      error => this.onError(error));
  }

  private validateInvitation(): Promise<FriendInvitation> {

    return this.invService.validateInvitation(this.user)
      .then(res => {
        if (res.status_code === 200) {
          const inv: FriendInvitation = res.data[0];
          if (inv.user_exist !== 0) {
            this.user.user_token = inv.user_token;
            this.loadUserInfo(this.user); return null;
          } else {
            const emailx: string = res.data[0].email;
            this.user.info = { email: emailx } as UserInfo;
            this.registration.email = emailx;
            return res.data[0]; }
        } else {
          this.onError(res.msg); return null; }
      },
      error => { console.error(error); return null; });
  }

  private loadUserInfo(user: User) {

    this.loginService.findUserInfo(user).then(res => {
      if (res.status_code === 200) {
        user.info = res.data[0];
        this.loginService.saveUser(user);
        this.router.navigate(['/myCalendar']);
      } else { this.onError(res.msg); }
    },
    error => { console.error(error); });
  }

  public onFlagSelection(flagKey: string) {
    this.registration[flagKey] = this.registration[flagKey] ? 0 : 1;
  }

  public register(form: NgForm) {
    this.submitted = true;
    if (form.invalid) { return false; }

    this.submitting = true;
    this.regService.saveUser(this.user, this.registration)
      .then(res => {
        if (res.status_code === 200) {
          this.user = Object.assign(this.user, res.data[0]);
          this.user.info = Object.assign(
                this.user.info, this.registration);
          this.confirmInvitation();
        } else {
          this.showErrorPopup(res.msg); }
      }, err => console.error(err))
    .finally(() => this.submitting = false);
  }

  private confirmInvitation() {

    this.invService.confirmInvitation(this.user)
      .then(res => {
        if (res.status_code === 200) {
          this.loginService.saveUser(this.user);
          this.router.navigate(['/myCalendar']);
        } else {
          this.showErrorPopup(res.msg); }
      }, err => console.error(err));
  }

  private showErrorPopup(error: string) {
    this.modalService.open(this.popupError);
    console.error(error);
  }

  private onError(error: any) {
    this.router.navigate(['/error'], { skipLocationChange: true});
    console.error(error);
  }
}
