import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot,
         RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { LoginService } from '../services/login/login.service';
import { User } from '../models/userPojos';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private loginService: LoginService,
              private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean | UrlTree {

    const user: User = this.loginService.getUser();
    if (user === null) {
      this.router.navigate(['/error'],
        { queryParams: { auth: true }, skipLocationChange: true});
      return false;
    } else { return true; }
  }
}
