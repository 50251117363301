import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { PrivateSlot } from 'src/app/models/availabilityPojos';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-reservation-popup',
  templateUrl: './delete-reservation-popup.component.html',
  styleUrls: ['./delete-reservation-popup.component.css']
})
export class DeleteReservationPopupComponent implements OnInit {

  @Input() slot: PrivateSlot;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() { }
}
