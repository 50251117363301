
import { GenericResponse } from './genericResponse';

class UserAuth {
  public token: string = null ;
  public otp: string = null ;
}
class UserAuthResponse extends GenericResponse {
  public data: [{
    flg_privacy: number ;
    user_token: string ;
    user_component: string ;
  }];
}

class UserInfo {
  email: string = null;
  flag_manleva = 0 ;
  user_firstname: string = null ;
  user_fiscalcode: string = null ;
  user_lastname: string = null ;
  user_phone: string = null ;
}
class User {
  public auth: UserAuth = null;
  public flg_privacy = 0 ;
  public user_token: string = null ;
  public user_component: string = null ;
  public user_uuid: string = null;
  public info = new UserInfo() ;
}
class UserInfoResponse extends GenericResponse {
  public data: UserInfo[];
}

export { User, UserAuth, UserAuthResponse,
         UserInfoResponse, UserInfo };
