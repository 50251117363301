import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PrivateCalendarPageComponent } from './private-calendar-page/private-calendar-page.component';
import { MyReservationsPageComponent } from './my-reservations-page/my-reservations-page.component';
import { AcceptInvitePageComponent } from './accept-invite-page/accept-invite-page.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { AuthGuard } from './utils/auth.guard';

const routes: Routes = [
  { path: '', component: LandingPageComponent },
  { path: 'myCalendar', component: PrivateCalendarPageComponent },
  { path: 'myReservations',
    component: MyReservationsPageComponent,
    canActivate: [AuthGuard] },
  { path: 'acceptInvite', component: AcceptInvitePageComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: ':location', component: LandingPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
