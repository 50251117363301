
class CalendarFilter {
  public from: number;
  public to: number;
  public state: string;
  public sport: string[];
  public component: string;
}

export { CalendarFilter };
