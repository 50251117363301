import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor,
         HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from '../services/login/login.service';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private loginService: LoginService,
              private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(catchError(error => this.checkSessionValidity(error)));
  }
  private checkSessionValidity(error: any): Observable<any> {
    // check response to verify session validity
    // if response status equals 403 then force user logout and redirect
    if (error.status === 403) {
      this.loginService.logout();
      this.router.navigate(['/error'],
        { queryParams: { auth: true } , skipLocationChange: true});
    // else if response status not equals 200 redirect to custom error page
    } else {
      this.router.navigate(['/error'], { skipLocationChange: true});
    }
    return throwError(error);
  }
}
