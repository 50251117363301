import { Injectable, HostListener } from '@angular/core';
import { Subject, Observable } from 'rxjs';

const MediaQuery = [
  '(max-width: 480px)',       // MOBILE
  '(max-width: 1024px)'       // TABLET
];
export enum DeviceType { MOBILE, TABLET, DESKTOP }

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectorService {

  private device: DeviceType = null;
  private deviceChangeSource = new Subject<void>();
  private deviceChange$ = this.deviceChangeSource.asObservable();

  constructor() {
    window.addEventListener('resize', evt => {
      this.getDeviceTypeUsingMediaQuery();
      this.deviceChangeSource.next();
    });
    this.getDeviceTypeUsingMediaQuery(); }

  public get deviceType(): DeviceType { return this.device; }

  public get deviceChange(): Observable<void> { return this.deviceChange$; }

  private getDeviceTypeUsingMediaQuery(): void {

    if (this.isDevice(DeviceType.MOBILE)) {
      this.device = DeviceType.MOBILE;
    } else if (this.isDevice(DeviceType.TABLET)) {
      this.device = DeviceType.TABLET;
    } else { this.device = DeviceType.DESKTOP; }
  }

  private isDevice(type: DeviceType): boolean {
    return window.matchMedia(MediaQuery[type.valueOf()]).matches;
  }
}
