
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppLoadService } from './services/configuration/app-load.service';

@NgModule({
  declarations: [ ],
  imports: [ CommonModule ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: AppLoadService.initializer,
    deps: [ AppLoadService, Injector ],
    multi: true
  }]
})
export class AppLoadModule { }
