import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import * as $ from 'jquery';
import * as WOW from 'wow.js/dist/wow';
import { LoginService } from '../services/login/login.service';
import { User } from '../models/userPojos';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarObservableService } from '../services/calendar/calendar.observable.service';
import { Subscription } from 'rxjs';
import { AppLoadService } from '../services/configuration/app-load.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit, OnDestroy {

  @ViewChild('popup_info', { static: true }) private popupInfo: TemplateRef<any>;

  public configuration = this.configService.getConfiguration();
  public user: User = null;
  private calendarSub: Subscription = null;

  constructor(private loginService: LoginService,
              private modalService: NgbModal,
              private calendarService: CalendarObservableService,
              private configService: AppLoadService) { }

  ngOnInit() {
    // this.user = this.loginService.getUser();
    // this.calendarSub = this.calendarService
    //     .publicInfo$.subscribe(() => this.showInfoPopup());
    this.initGraphicStuff();
  }
  ngOnDestroy() { this.calendarSub.unsubscribe(); }

  private initGraphicStuff() {

    if ($(window).width() > 767) { new WOW().init(); }
    jQuery.fn.scrollUp({
      scrollSpeed: 1500,
      scrollText: '<i class="fa fa-angle-up"></i>'
    });
    this.initTimelineBlock();               // initialize timeline block
  }
  private initTimelineBlock() {
    const $timelineBlock = $('.cd-timeline-block');

    $timelineBlock.each(function() {
      const offset = $(window).scrollTop() + $(window).height() * 0.75;
      if ($(this).offset().top > offset) {
        $(this).find('.cd-timeline-img, .cd-timeline-content')
            .addClass('is-hidden');
      }
    });
    $(window).on('scroll', () => {
      $timelineBlock.each(function() {
        const offset = $(window).scrollTop() + $(window).height() * 0.75;
        if ($(this).offset().top <= offset &&
            $(this).find('.cd-timeline-img').hasClass('is-hidden')) {
          $(this).find('.cd-timeline-img, .cd-timeline-content')
                 .removeClass('is-hidden').addClass('bounce-in');
        }
      });
    });
  }

  private showInfoPopup() {
    this.modalService.open(this.popupInfo); }
}
