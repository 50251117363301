
import { GenericResponse } from './genericResponse';

class Slot {
  slot_cale_id: number ;
  slot_id: number;
  slot_stato: string;
  slot_sport: string;
  slot_timestamp_from: string;
  slot_timestamp_to: string;
  slot_category: string;
}
class PrivateSlot extends Slot {
  slot_reservation: Reservation;
}
class Reservation {
  rese_code: string ;
  rese_date_reservation: Date ;
  rese_date_update: Date ;
  rese_id: number ;
  rese_type: string ;
  rese_uuid: string ;
}

class PublicAvailabilityResponse extends GenericResponse {
  public data: Slot[];
}
class PrivateAvailabilityResponse extends GenericResponse {
  public data: PrivateSlot[];
}

export { PublicAvailabilityResponse,
         PrivateAvailabilityResponse,
         Slot, PrivateSlot, Reservation };
