
import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { RegistrationFstStep, RegistrationSndStep } from '../../models/registrationPojos';
import { NgForm } from '@angular/forms';
import { RegistrationService } from '../../services/registration/registration.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Configuration } from 'src/app/models/configurationPojos';
import { AppLoadService } from 'src/app/services/configuration/app-load.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  public config = this.configService.getConfiguration();

  @ViewChild('popup_success', { static: true }) private popupSuccess: TemplateRef<any>;
  @ViewChild('popup_error', { static: true })   private popupError: TemplateRef<any>;
  @ViewChild('popup_privacy', { static: true }) private popupPrivacy: TemplateRef<any>;

  public submittingFstStep = false;
  public submittedFstStep  = false;
  public submittedSndStep  = false;
  public submittingSndStep = false;
  public fstStep: RegistrationFstStep ;
  public sndStep: RegistrationSndStep ;

  private privacyModalRef: NgbModalRef;

  constructor(private regService: RegistrationService,
              private modalService: NgbModal,
              private configService: AppLoadService) {

    this.fstStep = new RegistrationFstStep();
    this.sndStep = new RegistrationSndStep();
  }

  ngOnInit() {
    this.fstStep.component = this.config.component;
  }

  //////////////////////////////////////// REGISTRATION : FIRST STEP

  public checkEmail(fstStepForm: NgForm) {
    this.submittedFstStep  = true;
    if (!fstStepForm.valid || this.submittingFstStep) { return ; }

    this.submittingFstStep = true;
    this.regService.userExists(this.fstStep).then(res => {
      if (res.status_code === 200) {
        this.checkEmailSuccess(res.data[0].user_exist);
      } else {
        this.showErrorPopup(res.msg); }
    }, err => console.error(err))
    .finally(() => {
      this.submittedFstStep  = false;
      this.submittingFstStep = false;
    });
  }

  private checkEmailSuccess(userExists: boolean) {

    if (userExists) {
      this.regService.loginOTP(this.fstStep).then(res => {
        if (res.status_code === 200) {
          this.modalService.open(this.popupSuccess);
        } else {
          this.showErrorPopup(res.msg); }
      }, err => console.error(err));
    } else {
      // prepare second registration step
      this.sndStep.email = this.fstStep.email;
      this.sndStep.component = this.fstStep.component;
      this.privacyModalRef = this.modalService
          .open(this.popupPrivacy);
      this.privacyModalRef.result
          .finally(() => this.resetFlags());
    }
  }

  //////////////////////////////////////// REGISTRATION : SECOND STEP

  public onFlagSelection(flagKey: string) {
    this.sndStep[flagKey] = this.sndStep[flagKey] ? 0 : 1;
  }

  public submitPrivacy(sndStepForm: NgForm) {
    this.submittedSndStep = true;
    if (!sndStepForm.valid) { return false; }

    this.submittingSndStep = true;
    this.regService.registerUserOTP(this.sndStep).then(res => {
      if (res.status_code === 200) {
        this.modalService.open(this.popupSuccess);
      } else {
        this.showErrorPopup(res.msg); }
    }, err => console.error(err))
    .finally(() => {
      this.submittingSndStep = false;
      this.privacyModalRef.close(); });
  }

  private resetFlags() {
    this.sndStep = new RegistrationSndStep();
    this.submittedSndStep = false;
  }

  private showErrorPopup(error: string) {
    this.modalService.open(this.popupError);
    console.error(error);
  }
}
