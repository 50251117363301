import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { User, UserInfo } from '../models/userPojos';
import { LoginService } from '../services/login/login.service';
import * as $ from 'jquery';
import * as WOW from 'wow.js/dist/wow';
import { CalendarService } from '../services/calendar/calendar.service';
import { CalendarFilter } from '../models/filterPojos';
import { PrivateSlot, PrivateAvailabilityResponse } from '../models/availabilityPojos';
import { Invitation } from '../models/invitationPojos';
import { NgForm } from '@angular/forms';
import { InvitationService } from '../services/invitation/invitation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GenericResponse } from '../models/genericResponse';
import { Router } from '@angular/router';
import { ReservationService } from '../services/reservation/reservation.service';
import { DeleteReservationPopupComponent } from './delete-reservation-popup/delete-reservation-popup.component';
import { AppLoadService } from '../services/configuration/app-load.service';

@Component({
  selector: 'app-my-reservations-page',
  templateUrl: './my-reservations-page.component.html',
  styleUrls: ['./my-reservations-page.component.css']
})
export class MyReservationsPageComponent implements OnInit {

  @ViewChild('popup_invitation_okay', { static: true })  private popupOkay  : TemplateRef<any>;
  @ViewChild('popup_invitation_error', { static: true }) private popupError : TemplateRef<any>;
  @ViewChild('popup_invitation_error_expired', { static: true }) private popupErrorExpired : TemplateRef<any>;

  public configuration = this.configService.getConfiguration();

  public user: User = null;
  public reservationSlots$: Promise<{
    counter: number;
    slot: PrivateSlot;
    invitation: Invitation;
    guest: UserInfo }[]> = null;
  public maxInvitationsPerReservation = 5;
  public invitationsRange = new Array(this.maxInvitationsPerReservation);
  public msg: string = null;
  public pageSize = 12;
  public currentPage = 1;

  constructor(private loginService: LoginService,
              private availService: CalendarService,
              private invService: InvitationService,
              private modalService: NgbModal,
              private router: Router,
              private resService: ReservationService,
              private configService: AppLoadService) { }

  ngOnInit() {
    if ($(window).width() > 767) { new WOW().init(); }

    this.user = this.loginService.getUser();
    const filter: CalendarFilter = new CalendarFilter();
    this.reservationSlots$ = this.availService
      .getPrivateAvailability(this.user, filter, true, false).then(res => {
        if (res.status_code === 200) {
          return this.onSuccess(res);
        } else {
          this.router.navigate(['/error'], { skipLocationChange: true });
          console.error(res.msg); return null; }
      },
      err => { console.error(err); return null; });
  }

  public onPageChanged(pageNum) { this.currentPage = pageNum; }

  private onSuccess(res: PrivateAvailabilityResponse)
  : { counter: number; slot: PrivateSlot; invitation: Invitation; }[] {

    return res.data.map(privateSlot => {
      return  { slot: privateSlot,
        counter: 1, collapse: true,
        guest: null, submitting: false,
        invitation: {
          reseid: privateSlot.slot_reservation.rese_id,
          slotid: privateSlot.slot_id,
          emails: Array(this.maxInvitationsPerReservation)
        } as Invitation };
    });
  }

  public increaseCounter(ref: { counter: number }) {
    if (ref.counter < this.maxInvitationsPerReservation) {
        ref.counter++; }
  }
  public decreaseCounter(ref: { counter: number,
                                invitation: Invitation }) {
    if (ref.counter > 1) {
      delete ref.invitation.emails[--ref.counter]; }
  }
  public showInvitationForm(ref: { collapse: boolean,
                                   counter: number,
                                   invitation: Invitation }) {
    ref.collapse = !ref.collapse;
    if (!ref.collapse) {
      ref.invitation.emails = []; ref.counter = 1; }
  }

  public submitInvitations(form: NgForm, data: any) {

    if (form.invalid || !data.invitation
        .emails.some(email => email && email.length !== 0)) {
      form.control.setErrors({}); return false;
    }
    const invitation: Invitation = Object.assign(
      { }, data.invitation, {
        emails: data.invitation.emails
          .filter(email => email && email.length !== 0)
    });
    data.submitting = true;
    this.invService.sendInvitations(this.user, invitation)
      .then(res => this.sendInvitationSuccess(res, data),
            err => console.error(err))
      .finally(() => data.submitting = false);
  }

  private sendInvitationSuccess(res: GenericResponse, data: any) {
    this.msg = res.msg;
    switch (res.status_code) {
      case 200 : this.showSuccessPopup(data); break;
      case 888 : this.showErrorExpiredPopup(res.msg);
                 break;
      default  : this.showErrorPopup(res.msg);
    }
  }

  public getGuestInfo(data: { slot: PrivateSlot, guest: UserInfo }) {

    this.loginService.findUserByUuid(
        this.user, data.slot.slot_reservation.rese_uuid)
      .then(res => {
          if (res.status_code === 200) {
            data.guest = res.data[0];
          } else { this.showErrorPopup(res.msg); }
        },
        err => console.error(err));
  }

  private removeReservation(slot: PrivateSlot) {

    this.resService.reservationDelete(this.user, slot.slot_id)
      .then(result => {
        if (result.status_code === 200) {
          this.reservationSlots$ = this.reservationSlots$
            .then(reservations => {
              const reseIdx: number = reservations
                .findIndex(r => r.slot.slot_id === slot.slot_id);
              reservations.splice(reseIdx, 1);
              return reservations;
          });
        } else { this.showErrorPopup(result.msg); }
      }, err => console.error(err));
  }

  public goToMyCalendarPage() { this.router.navigate(['/myCalendar']); }

  private showSuccessPopup(data: any) {
    this.modalService.open(this.popupOkay)
      .result.finally(() => this.showInvitationForm(data));
  }
  public showReservationDeletionPopup(slot: PrivateSlot) {
    const ref = this.modalService.open(DeleteReservationPopupComponent);
    ref.componentInstance.slot = slot;
    ref.result.then(res => this.removeReservation(slot), err => void(0));
  }
  private showErrorPopup(error: string) {
    this.modalService.open(this.popupError);
    console.error(error);
  }
  private showErrorExpiredPopup(error: string) {
    this.modalService.open(this.popupErrorExpired);
    console.error(error);
  }
}
