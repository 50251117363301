import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ConfigurationResponse, Configuration } from '../../models/configurationPojos';

@Injectable({
  providedIn: 'root'
})
export class AppLoadService {

  ////////////// STATIC METHODS TO INITIALIZE APPLICATION ON START-UP

  public static initializer(
    loader: AppLoadService, inj: Injector): () => Promise<any> {
    return () => AppLoadService.init(loader, inj);
  }

  private static init(loader: AppLoadService, inj: Injector): Promise<void> {

    const subdomain = loader.getSubdomainFromHost();
    if (subdomain === null) {
      AppLoadService.initError(inj, 'Invalid subdomain');
    }
    else if (loader.getSubdomain() === null) {
      return loader.loadConfiguration(subdomain).then(response => {
        if (response.status_code == 200) {
          loader.saveSubdomain(subdomain);
          console.log(response);
          loader.saveConfiguration(response.data[0]);
        } else {
          AppLoadService.initError(inj, response.msg);
        }
      },
        error => AppLoadService.initError(inj, error));
    }
  }

  private static initError(injector: Injector, error: any) {
    injector.get(Router).navigate(['/error'],
      { queryParams: { load: true }, skipLocationChange: true });
    console.error(error);
  }

  /////////////////////////////////// APP LOADER SERVICE IMPLEMENTATION

  constructor(private http: HttpClient) { }

  public getSubdomainFromHostname(): string {

    const pos = location.hostname.lastIndexOf(environment.rootDomain);
    return pos === -1 ? null :
      location.hostname.substr(0, pos);
  }

  public getSubdomainFromHost(): string {
    const domain = window.location.hostname;
    if (domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'example' || domain.split('.')[0] === 'lvh' || domain.split('.')[0] === 'www') {
      return '';
    } else {
      return domain.split('.')[0];
    }
  }

  public loadConfiguration(subdomain: string): Promise<ConfigurationResponse> {

    const url = environment.configEndpoint
      + environment.configBasePath;

    return this.http.get<ConfigurationResponse>(url, {
      headers: environment.header,
      params: { domain: subdomain }
    }
    ).toPromise();
  }

  private saveSubdomain(subdomain: string) {

    localStorage.setItem('subdomain', subdomain);
  }
  public getSubdomain(): string {
    return localStorage.getItem('subdomain');
  }

  private saveConfiguration(conf: Configuration) {

    localStorage.setItem('configuration', JSON.stringify(conf));
  }
  public getConfiguration(): Configuration {

    return JSON.parse(localStorage.getItem('configuration'));
  }
}
