import { Component, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { CalendarObservableService } from '../../services/calendar/calendar.observable.service';
import { PrivateSlot } from '../../models/availabilityPojos';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Reservation, ReservationResponse } from '../../models/reservationPojos';
import { NgForm } from '@angular/forms';
import { ReservationService } from '../../services/reservation/reservation.service';
import { User } from '../../models/userPojos';
import { LoginService } from '../../services/login/login.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AppLoadService } from 'src/app/services/configuration/app-load.service';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})
export class ReservationComponent implements OnInit, OnDestroy {

  @ViewChild('popup_reservation', { static: true }) private popupReservation : TemplateRef<any>;
  @ViewChild('popup_reservation_error', { static: true }) private popupError : TemplateRef<any>;
  @ViewChild('popup_reservation_maxno', { static: true }) private popupMaxNo : TemplateRef<any>;
  @ViewChild('popup_reservation_okay', { static: true })  private popupOkay  : TemplateRef<any>;
  @ViewChild('popup_reservation_details', { static: true }) private popupDet : TemplateRef<any>;
  @ViewChild('popup_reservation_notresvable', { static: true }) private popupNotReservable : TemplateRef<any>;

  public configuration = this.configService.getConfiguration();

  public selectedSlot: PrivateSlot = new PrivateSlot();
  public user: User = null;
  public reservation: Reservation = new Reservation();
  public submittedReservation  = false;
  public submittingReservation = false;
  private popupReservationRef: NgbModalRef;
  public msg: string = null;
  private actionSub : Subscription;
  private detailsSub: Subscription;

  constructor(private eventService: CalendarObservableService,
              private modalService: NgbModal,
              private userService: LoginService,
              private reservationService: ReservationService,
              private router: Router,
              private configService: AppLoadService) { }

  ngOnInit() {
    this.user = this.userService.getUser();

    this.actionSub =  this.eventService
      .reservationActions$.subscribe(
        slot => this.onReservationActionEvent(slot));

    this.detailsSub = this.eventService
      .reservationDetails$.subscribe(
        slot => this.onReservationDetailsEvent(slot));
  }
  ngOnDestroy() {
    this.actionSub .unsubscribe();
    this.detailsSub.unsubscribe();
  }

  ///////////////////////////////////////////////// RESERVATION METHODS

  private onReservationActionEvent(slot: PrivateSlot) {
    this.selectedSlot = slot;
    this.popupReservationRef = this
      .modalService.open(this.popupReservation);

    this.popupReservationRef.result
      .finally(() => this.resetReservationForm());
  }

  public onManlevaSelection() {
    this.reservation.flag_manleva = this.reservation.flag_manleva ? 0 : 1;
  }
  public submitReservation(reservationForm: NgForm) {

    this.submittedReservation = true;
    if (!reservationForm.valid) { return false; }

    this.submittingReservation = true;
    this.reservation.slot_id = this.selectedSlot.slot_id;
    this.reservationService
      .slotReservation(this.user, this.reservation)
      .then(res => this.onReservationSuccess(res),
            err => console.error(err))
      .finally(() => this.submittingReservation = false);
  }
  private onReservationSuccess(res: ReservationResponse) {

    switch (res.status_code) {
      case 200:
        delete this.reservation.slot_id;
        Object.assign(this.user.info, this.reservation);
        this.userService.saveUser(this.user);

        this.eventService.refreshCalendarSource.next();
        this.showSuccessPopup(); break;
      case 666:
        this.msg = res.msg;
        this.showMaxReservationPopup(); break;
      case 888:
        this.msg = res.msg;
        this.showNotReservablePopup(); break;

      default: this.showErrorPopup(res.msg);
    }
  }
  private resetReservationForm() {
    this.submittedReservation = false;
    this.reservation = new Reservation();
  }

  private showSuccessPopup() {
    this.popupReservationRef.close();
    this.modalService.open(this.popupOkay);
  }
  private showMaxReservationPopup() {
    this.popupReservationRef.close();
    this.modalService.open(this.popupMaxNo);
  }
  private showNotReservablePopup() {
    this.popupReservationRef.close();
    this.modalService.open(this.popupNotReservable);
  }
  private showErrorPopup(error: string) {
    this.popupReservationRef.close();
    this.modalService.open(this.popupError);
    console.error(error);
  }

  ///////////////////////////////////////////////// RESERVATION DETAILS METHODS

  private onReservationDetailsEvent(slot: PrivateSlot) {
    this.selectedSlot = slot;
    this.modalService.open(this.popupDet);
  }

  public goToMyReservationPage() {
    this.router.navigate(['/myReservations']);
  }
}
